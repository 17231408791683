var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('CRow',[_c('CCol',{staticClass:"mt-3 d-flex justify-content-start px-0",attrs:{"sm":"10"}},[_c('CSelect',{staticClass:"col-sm-9",attrs:{"label":_vm.$t('label.vehicleClasification')+':',"horizontal":{label: 'col-auto', input:'col-sm-6'},"add-label-classes":"text-left","options":_vm.computedClassVehicle,"value":_vm.computedClassVehicle},on:{"change":function($event){return _vm.filterSelectedClass($event)}},model:{value:(_vm.VehicleClassifId),callback:function ($$v) {_vm.VehicleClassifId=$$v},expression:"VehicleClassifId"}})],1),_c('CCol',{staticClass:"d-flex justify-content-end align-items-center",attrs:{"sm":"2"}},[_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
          content: _vm.$t('label.vehicle'),
          placement: 'top-end'
        }),expression:"{\n          content: $t('label.vehicle'),\n          placement: 'top-end'\n        }"}],staticClass:"d-flex align-items-center",attrs:{"color":"add"},on:{"click":_vm.toggleShowModalCreateChofer}},[_c('CIcon',{attrs:{"name":"cil-playlist-add"}}),_c('span',{staticClass:"ml-1"},[_vm._v(_vm._s(_vm.$t('label.nuevo')))])],1)],1)],1),_c('dataTableExtended',{staticClass:"align-center-row-datatable",attrs:{"size":"lg","fields":_vm.fields,"items":_vm.computedVehicleList,"sorter":"","items-per-page-select":_vm.tableText.itemsPerPageText,"items-per-page":_vm.tableText.itemsPerPage,"noItemsView":_vm.tableText.noItemsViewText,"loading":_vm.loadingTable,"hover":"","paginator":{ TotalPages: _vm.totalPages, TotalRecords: _vm.totalRecords, Page: _vm.page}},on:{"pagination-change":function($event){return _vm.changePagination($event)}},scopedSlots:_vm._u([{key:"over-table",fn:function(){return [_c('div',{staticClass:"row justify-content-start",staticStyle:{"margin-top":"-2.8rem"}},[_c('div',{staticClass:"col-auto px-2"},[_c('label',{staticClass:"p-1 mt-1"},[_vm._v(_vm._s(_vm.$t('label.filter'))),_c('b',[_vm._v(": ")])])]),_c('div',{staticClass:"col-auto px-0"},[_c('CInput',{directives:[{name:"uppercase",rawName:"v-uppercase"}],staticClass:"col-sm-12 px-0",attrs:{"placeholder":_vm.$t('label.enterSearch')},model:{value:(_vm.searchKey),callback:function ($$v) {_vm.searchKey=$$v},expression:"searchKey"}})],1),_c('div',{staticClass:"col-auto px-2"},[_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
              content: _vm.$t('label.search')+' '+_vm.$t('label.vehicle'),
              placement: 'top',
            }),expression:"{\n              content: $t('label.search')+' '+$t('label.vehicle'),\n              placement: 'top',\n            }"}],staticClass:"d-flex align-items-center",attrs:{"color":"watch"},on:{"click":_vm.searchAdvanceFilter}},[_c('CIcon',{attrs:{"name":"cil-search"}})],1)],1),_c('div',{staticClass:"col-auto px-0"},[_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
              content: _vm.$t('label.reset')+_vm.$t('label.table'),
              placement: 'top',
            }),expression:"{\n              content: $t('label.reset')+$t('label.table'),\n              placement: 'top',\n            }"}],staticClass:"d-flex align-items-center",attrs:{"color":"edit"},on:{"click":_vm.resetDataOfTable}},[_c('CIcon',{attrs:{"name":"cil-loop-circular"}})],1)],1)])]},proxy:true},{key:"loading",fn:function(){return [_c('loading')]},proxy:true},{key:"under-table",fn:function(){return [(_vm.totalPages > 0)?_c('div',{staticClass:"ml-3 mb-3"},[_c('CPagination',{attrs:{"activePage":_vm.page,"pages":_vm.totalPages,"size":"sm"},on:{"update:activePage":[function($event){_vm.page=$event},function($event){return _vm.updatePage($event)}],"update:active-page":function($event){_vm.page=$event}}})],1):_vm._e()]},proxy:true},{key:"Status",fn:function({ item }){return [_c('td',{staticClass:"center-cell"},[_c('CBadge',{attrs:{"color":_vm.getBadge(item.Status)}},[_vm._v(" "+_vm._s(_vm.$t('label.'+item.Status))+" ")])],1)]}},{key:"options",fn:function({ item }){return [_c('td',{staticClass:"center-cell"},[_c('CButtonGroup',[_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
              content: _vm.$t('label.edit')+' '+_vm.$t('label.vehicle'),
              placement: 'top-end'
            }),expression:"{\n              content: $t('label.edit')+' '+$t('label.vehicle'),\n              placement: 'top-end'\n            }"}],attrs:{"color":"edit","size":"sm"},on:{"click":function($event){return _vm.toggleShowModalEditVehicle(item)}}},[_c('CIcon',{attrs:{"name":"pencil"}})],1)],1)],1)]}}])}),_c('modalVehiculo',{attrs:{"modal":_vm.showModal,"title":_vm.titleModal,"editModal":_vm.editModal,"objectVehicle":_vm.objectVehicle},on:{"update:modal":function($event){_vm.showModal=$event},"updated-modal":_vm.updateCLoseModal,"refresh-data-table":_vm.resetDataOfTable}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }